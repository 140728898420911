import { PathLayer } from '@deck.gl/layers';
import { createIterable } from '@deck.gl/core';

// todo - based on https://github.com/uber/deck.gl/blob/7.2-release/modules/geo-layers/src/trips-layer/trips-layer.js

const defaultProps = {
  trailLength: { type: 'number', value: 120, min: 0 },
  currentTime: { type: 'number', value: 0, min: 0 },
  getTimestamps: { type: 'accessor', value: null },
};

export default class LogsLayer extends PathLayer {
  constructor(props: any) {
    super(props);
  }

  // todo - shader injection of hits is really dirty, but works for now

  getShaders() {
    const shaders = super.getShaders();
    shaders.inject = {
      // Timestamp of the vertex
      'vs:#decl': `\
        uniform float trailLength;
        uniform bool isPath3D;
        attribute vec2 instanceTimestamps;
        attribute vec2 instanceHit;
        varying float vTime;
        varying float vHit;
      `,
      // Remove the z component (timestamp) from position
      // TODO - Legacy use case, remove in v8
      'vec3 nextPosition = mix(instanceEndPositions, instanceRightPositions, isEnd);': `\
        vec2 timestamps = instanceTimestamps;
        if (!isPath3D) {
          prevPosition.z = 0.0;
          currPosition.z = 0.0;
          nextPosition.z = 0.0;
          timestamps.x = instanceStartPositions.z;
          timestamps.y = instanceEndPositions.z;
        }
      `,
      // Apply a small shift to battle z-fighting
      'vs:#main-end': `\
        float shiftZ = sin(timestamps.x) * 1e-4;
        gl_Position.z += shiftZ;
        vTime = timestamps.x + (timestamps.y - timestamps.x) * vPathPosition.y / vPathLength;
        vHit = instanceHit.x;
        `,
      'fs:#decl': `\
        uniform float trailLength;
        uniform float currentTime;
        varying float vTime;
        varying float vHit;
      `,
      // Drop the segments outside of the time window
      'fs:#main-start': `\
        if(vTime > currentTime || vTime < currentTime - trailLength) {
          discard;
        }
      `,
      // Fade the color (currentTime - 100%, end of trail - 0%)
      'fs:DECKGL_FILTER_COLOR': 'color.g = vHit; color.a *= 1.0 - (currentTime - vTime) / trailLength;',
    };
    return shaders;
  }

  initializeState(params: any) {
    super.initializeState(params);

    const attributeManager = this.getAttributeManager();
    attributeManager.addInstanced({
      instanceTimestamps: {
        size: 2,
        update: this.calculateInstanceTimestamps,
      },
      instanceHit: {
        size: 2, // todo - value `2` is important - otherwise hits will be off
        update: this.calculateInstanceHits,
      },
    });
  }

  draw(params: any) {
    const { trailLength, currentTime, getTimestamps } = this.props;

    params.uniforms = Object.assign({}, params.uniforms, {
      trailLength,
      currentTime,
      // TODO - remove in v8
      isPath3D: Boolean(getTimestamps),
    });

    super.draw(params);
  }

  calculateInstanceHits(attribute: any, { startRow, endRow }: any) {
    // todo - revise - for now just copied `calculateInstanceTimestamps`
    const { data, getHits } = this.props;

    if (!getHits) {
      // TODO - Legacy use case, remove in v8
      attribute.constant = true;
      attribute.value = new Float32Array(2);
      return;
    }

    const {
      pathTesselator: { bufferLayout, instanceCount },
    } = this.state;
    const value = new Float32Array(instanceCount * 2);

    const { iterable, objectInfo } = createIterable(data, startRow, endRow);
    let i = 0;

    for (let objectIndex = 0; objectIndex < startRow; objectIndex++) {
      i += bufferLayout[objectIndex] * 2;
    }

    for (const object of iterable) {
      objectInfo.index++;

      const geometrySize = bufferLayout[objectInfo.index];
      const timestamps = getHits(object, objectInfo);
      // For each line segment, we have [startTimestamp, endTimestamp]
      for (let j = 0; j < geometrySize; j++) {
        value[i++] = timestamps[j];
        value[i++] = timestamps[j + 1];
      }
    }
    attribute.constant = false;
    attribute.value = value;
  }

  calculateInstanceTimestamps(attribute: any, { startRow, endRow }: any) {
    const { data, getTimestamps } = this.props;

    if (!getTimestamps) {
      // TODO - Legacy use case, remove in v8
      attribute.constant = true;
      attribute.value = new Float32Array(2);
      return;
    }

    const {
      pathTesselator: { bufferLayout, instanceCount },
    } = this.state;
    const value = new Float32Array(instanceCount * 2);

    const { iterable, objectInfo } = createIterable(data, startRow, endRow);
    let i = 0;

    for (let objectIndex = 0; objectIndex < startRow; objectIndex++) {
      i += bufferLayout[objectIndex] * 2;
    }

    for (const object of iterable) {
      objectInfo.index++;

      const geometrySize = bufferLayout[objectInfo.index];
      const timestamps = getTimestamps(object, objectInfo);
      // For each line segment, we have [startTimestamp, endTimestamp]
      for (let j = 0; j < geometrySize; j++) {
        value[i++] = timestamps[j];
        value[i++] = timestamps[j + 1];
      }
    }
    attribute.constant = false;
    attribute.value = value;
  }
}

LogsLayer.layerName = 'LogsLayer';
LogsLayer.defaultProps = defaultProps;
