/* TODO
- add easing to animation, in: BounceOut, out: SineIn
*/

export default `\
#define SHADER_NAME bumps-fill-layer-vertex-shader

attribute vec3 positions;

attribute vec3 instancePositions;
attribute vec2 instancePositions64xyLow;
attribute float instanceLineWidths;
attribute vec4 instanceFillColors;
attribute vec4 instanceLineColors;
attribute vec3 instancePickingColors;
attribute float instanceEntryTime;
attribute float instanceHit;

uniform float lineWidthScale;
uniform float radiusMinPixels;
uniform float radiusMaxPixels;
uniform float lineWidthMinPixels;
uniform float lineWidthMaxPixels;
uniform float stroked;
uniform bool filled;
uniform float currentTime;
uniform float durationIn;
uniform float durationOut;

varying vec4 vFillColor;
varying vec4 vLineColor;
varying vec2 unitPosition;
varying float innerUnitRadius;

void main(void) {
  float progressInRaw = clamp(
    (currentTime - instanceEntryTime) / durationIn,
    0.0, 1.0
  );
  float progressIn = progressInRaw - step(instanceEntryTime+durationIn,currentTime);

  float exitStart = instanceEntryTime + durationIn;
  float progressOut = step(exitStart,currentTime) - clamp(
    (currentTime - exitStart) / durationOut,
    0.0, 1.0
  );

  float progress = progressIn + progressOut;
  float opacity = (0.4 + 0.2 * instanceHit) * progress;
  geometry.worldPosition = instancePositions;

  // Multiply out radius and clamp to limits
  float outerRadiusPixels = clamp(
    project_size_to_pixel(progress * (20.0 + 20.0 * instanceHit)),
    radiusMinPixels, radiusMaxPixels
  );
  
  // Multiply out line width and clamp to limits
  float lineWidthPixels = clamp(
    project_size_to_pixel(lineWidthScale * instanceLineWidths),
    lineWidthMinPixels, lineWidthMaxPixels
  );

  // outer radius needs to offset by half stroke width
  outerRadiusPixels += stroked * lineWidthPixels / 2.0;

  // position on the containing square in [-1, 1] space
  unitPosition = positions.xy;
  geometry.uv = unitPosition;

  innerUnitRadius = 1.0 - stroked * lineWidthPixels / outerRadiusPixels;
  
  vec3 offset = positions * project_pixel_size(outerRadiusPixels);
  DECKGL_FILTER_SIZE(offset, geometry);
  gl_Position = project_position_to_clipspace(instancePositions, instancePositions64xyLow, offset, geometry.position);
  DECKGL_FILTER_GL_POSITION(gl_Position, geometry);

  // Apply opacity to instance color, or return instance picking color
  vFillColor = vec4(instanceFillColors.r + (0.013 + 0.023667 * instanceHit) * progressInRaw, instanceFillColors.g + (0.12 + 0.334902 * instanceHit) * progressInRaw, instanceFillColors.b, instanceFillColors.a * opacity);
  DECKGL_FILTER_COLOR(vFillColor, geometry);
  vLineColor = vec4(instanceLineColors.rgb, instanceLineColors.a * opacity);
  DECKGL_FILTER_COLOR(vLineColor, geometry);
  
  // Set color to be rendered to picking fbo (also used to check for selection highlight).
  picking_setPickingColor(instancePickingColors);
}
`;
