import React, { useState, useEffect, useCallback } from 'react';
import Map from './Map/Map';
import Logo from './Overlay/Logo/Logo';
import DetailInfo from './Overlay/DetailInfo/DetailInfo';
import './App.css';
import { LAYOUT } from './constants';

const App = () => {
  const [layout, setLayout] = useState(LAYOUT.DESKTOP);
  const [showDetail, setShowDetail] = useState(false);

  useEffect(() => {
    // todo - check if need to be defined here + make note about it to wiki (maybe create separate hook section)
    const handleResize = () => {
      if (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < 900) {
        setLayout(LAYOUT.MOBILE);
      } else {
        setLayout(LAYOUT.DESKTOP);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // todo - check if it's ok to call it here
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const handleShowDetail = useCallback(
    (value: boolean) => {
      setShowDetail(value);
    },
    [setShowDetail],
  );

  return (
    <div className="app">
      <Map onShowDetail={handleShowDetail} />
      <Logo layout={layout} />
      <DetailInfo showDetail={showDetail} layout={layout} />
    </div>
  );
};

export default App;
