import React, { Component } from 'react';
import { StaticMap } from 'react-map-gl';
import DeckGL from '@deck.gl/react';
import GL from '@luma.gl/constants';
import BumpsFillLayer from './Layers/BumpsLayer/BumpsFillLayer';
import BumpsStrokeLayer from './Layers/BumpsLayer/BumpsStrokeLayer';
import DotsLayer from './Layers/DotsLayer/DotsLayer';
import LogsLayer from './Layers/LogsLayer/LogsLayer';
import tripsJson from './trips.json';
// TODO - revise const assigning - sometimes can be probably moved out of class

const MAPBOX_TOKEN = 'pk.eyJ1Ijoiam9zZWR1IiwiYSI6ImNqeXgyeGJiNDB0N2wzbnJydHdnaWk5dW4ifQ.OTrmCrd0d6EVD3o0p5Ni7g';
const MAP_STYLE = 'mapbox://styles/josedu/ck0b1ctlq3bfu1cn44r3a7r0s'; // 3D
// const MAP_STYLE = 'mapbox://styles/josedu/ck003waqi28ws1cpl9y1n6rxv'; // 2D

const TRAIL_COLOR = [230, 0, 0];
const TRAIL_LENGTH = 300; // todo - set final value
const CHECK_INTERVAL = 5000; // ms
const FILTER_OFFSET = 200; // cleanup offse
const FILTER_OFFSET_BUMPS = 500; // cleanup offse
const LOOP_DELAY_BASE = 60 * 5;
const LOOP_DELAY_RANDOM = 60 * 5;

const INITIAL_VIEW_STATE = {
  longitude: 14.456861,
  latitude: 50.037918,
  zoom: 15,
  // minZoom: 15, // todo - probably set because of dissapearing buildings
  pitch: 35,
  bearing: 0, // rotation
  antialias: true, // todo - check if works
};

interface Props {
  onShowDetail: (show: boolean) => void;
}

interface State {
  trips: any[];
  bumps: any[];
  time: number;
}

// todo - consider rewriting to functional component
class Map extends Component<Props, State> {
  animationFrame: any;
  animLastTime: number; // todo - not ideal
  checkTime: number;

  constructor(props: any) {
    super(props);
    this.animLastTime = 0;
    this.checkTime = 0;

    const tripsData: any = tripsJson; // todo - data assigment is just temp and should be updated
    const bumps = [].concat(
      ...tripsData.trips.map((trip: any) => {
        return trip.bumps;
      }),
    );
    console.log(`Bumps: ${bumps.length}`);

    this.state = {
      trips: [...tripsData.trips],
      bumps,
      time: 0,
    };
  }

  componentDidMount() {
    // start animation
    this.animLastTime = Date.now();
    this.animate();
  }

  componentWillUnmount() {
    if (this.animationFrame) {
      window.cancelAnimationFrame(this.animationFrame);
    }
  }

  loopTrip(trip: any) {
    const startTime = trip.time[0];
    const offset = this.state.time + LOOP_DELAY_BASE + Math.round(Math.random() * LOOP_DELAY_RANDOM);
    return {
      pos: trip.pos,
      hits: trip.hits,
      time: trip.time.map((oldTime: number) => {
        return oldTime - startTime + offset;
      }),
      bumps: trip.bumps.map((oldBump: any) => {
        return {
          pos: oldBump.pos,
          time: oldBump.time - startTime + offset,
          hit: oldBump.hit,
        };
      }),
    };
  }

  checkTrips() {
    // todo - revise (late night) looping and optimise (is it necessary to update whole object each time?)
    const { trips, time } = this.state;
    let updated = false;
    const newBumps: any[] = [];

    const newTrips = trips.map(trip => {
      const lastTime = trip.time[trip.time.length - 1];
      if (lastTime + FILTER_OFFSET < time) {
        updated = true;
        const loopedTrip = this.loopTrip(trip);
        newBumps.push(...loopedTrip.bumps);
        return loopedTrip;
      } else {
        return trip;
      }
    });
    // set new data
    if (updated) {
      this.setState(state => {
        const upBups = [...state.bumps.filter(bump => bump.time + FILTER_OFFSET_BUMPS > time), ...newBumps];
        return {
          trips: newTrips,
          bumps: upBups,
        };
      });
    }
  }

  animate() {
    // todo - revise ticking function
    const currentTime = Date.now();
    const elapsedTime = Math.min(currentTime - this.animLastTime, 100);
    this.setState(state => {
      return { time: (state.time + elapsedTime * 0.05) % Number.MAX_SAFE_INTEGER }; // todo - check if modulo is ok like this
    });
    this.animLastTime = currentTime;
    // try check
    this.checkTime += elapsedTime;
    if (this.checkTime > CHECK_INTERVAL) {
      this.checkTime = 0;
      this.checkTrips();
    }
    this.animationFrame = window.requestAnimationFrame(this.animate.bind(this));
  }

  getDotPosition(time: number, dotData: any) {
    if (time <= dotData.time[0] || time >= dotData.time[dotData.time.length - 1]) {
      return [0, 0];
    }
    // todo - optimise
    const prog = [0, 1, 0.0]; // start/end/progress
    for (let i = 0; i < dotData.time.length; i++) {
      if (time <= dotData.time[i]) {
        prog[0] = i - 1;
        prog[1] = i;
        break;
      }
    }
    prog[2] = (time - dotData.time[prog[0]]) / (dotData.time[prog[1]] - dotData.time[prog[0]]);
    return [
      dotData.pos[prog[0]][0] + (dotData.pos[prog[1]][0] - dotData.pos[prog[0]][0]) * prog[2],
      dotData.pos[prog[0]][1] + (dotData.pos[prog[1]][1] - dotData.pos[prog[0]][1]) * prog[2],
    ];
  }

  renderLayers() {
    const { time } = this.state;

    return [
      new LogsLayer({
        id: 'logs',
        data: this.state.trips,
        getPath: (d: any) => d.pos,
        getTimestamps: (d: any) => d.time,
        getHits: (d: any) => d.hits,
        getColor: TRAIL_COLOR,
        opacity: 1.0,
        widthMinPixels: 2,
        rounded: true,
        trailLength: TRAIL_LENGTH,
        currentTime: time,
        shadowEnabled: false,
        parameters: {
          depthTest: false,
          blend: true,
          blendEquation: GL.FUNC_ADD,
        },
      }),
      new DotsLayer({
        id: 'trip-dots',
        data: this.state.trips,
        pickable: false,
        opacity: 1.0,
        stroked: false,
        filled: true,
        radiusMinPixels: 3,
        getPosition: (d: any) => this.getDotPosition(time, d),
        getRadius: 3.0,
        getFillColor: TRAIL_COLOR,
        currentTime: time,
      }),
      new BumpsFillLayer({
        id: 'bumps-fill-layer',
        data: this.state.bumps,
        pickable: false,
        stroked: false,
        filled: true,
        lineWidthMinPixels: 2,
        getPosition: (d: any) => d.pos,
        getEntryTime: (d: any) => d.time,
        getEntryHit: (d: any) => d.hit,
        currentTime: time,
        getFillColor: [230, 0, 0],
        parameters: {
          blend: true,
          blendEquation: GL.FUNC_ADD, // todo - check other options - https://github.com/uber/luma.gl/blob/master/modules/constants/src/index.js
          depthTest: false, // todo - check implementation, maybe set globally or at least to other layers https://github.com/uber/deck.gl/blob/master/docs/developer-guide/tips-and-tricks.md#z-fighting-and-depth-testing
        },
      }),
      // todo - consider custom layer implementation with custom radius calculation based on time
      new BumpsStrokeLayer({
        id: 'bumps-stroke-layer',
        data: this.state.bumps,
        pickable: false,
        stroked: true,
        filled: false,
        lineWidthMinPixels: 2,
        getPosition: (d: any) => d.pos,
        getEntryTime: (d: any) => d.time,
        getEntryHit: (d: any) => d.hit,
        currentTime: time,
        getLineColor: [233, 0, 0],
        parameters: {
          blend: true,
          blendEquation: GL.FUNC_ADD,
          depthTest: false,
        },
      }),
    ];
  }

  handleViewChange = ({ viewState, interactionState }: any) => {
    this.props.onShowDetail(!interactionState.isDragging && viewState.zoom > 16);
  };

  render() {
    return (
      <DeckGL
        useDevicePixels={false}
        layers={this.renderLayers()}
        initialViewState={INITIAL_VIEW_STATE}
        controller={true}
        onViewStateChange={this.handleViewChange}
      >
        <StaticMap reuseMaps mapStyle={MAP_STYLE} preventStyleDiffing={true} mapboxApiAccessToken={MAPBOX_TOKEN} />
      </DeckGL>
    );
  }
}

export default Map;
