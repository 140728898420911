/* TODO
- consider making animation more fluid by adding easing
- simplify - fill related stuff can be removed - keep just stroke related code
- check if progress/opacity calculation can be simplified (late night code)
- when simplified - check which atttributes and uniforms are actually used and clean even map layer settings
- investigate `discard` condition and set propper values to be sure it is called https://github.com/uber/deck.gl/blob/7.2-release/modules/layers/src/scatterplot-layer/scatterplot-layer-fragment.glsl.js
- check difference when using `varying` and `float xy` directly in function
- nice to have - find better way how to write shaders without wrapping everything in string (ok for simple shaders, bad for complex ones)
*/

export default `\
#define SHADER_NAME bumps-stroke-layer-vertex-shader

attribute vec3 positions;

attribute vec3 instancePositions;
attribute vec2 instancePositions64xyLow;
attribute float instanceLineWidths;
attribute vec4 instanceFillColors;
attribute vec4 instanceLineColors;
attribute vec3 instancePickingColors;
attribute float instanceEntryTime;
attribute float instanceHit;

uniform float lineWidthScale;
uniform float radiusMinPixels;
uniform float radiusMaxPixels;
uniform float lineWidthMinPixels;
uniform float lineWidthMaxPixels;
uniform float stroked;
uniform bool filled;
uniform float currentTime;
uniform float duration;

varying vec4 vFillColor;
varying vec4 vLineColor;
varying vec2 unitPosition;
varying float innerUnitRadius;

void main(void) {
  float progress = clamp(
    (currentTime - instanceEntryTime) / (duration + duration * 0.2 * instanceHit),
    0.0, 1.0
  );
  float opacity = step(instanceEntryTime,currentTime) - progress;
  geometry.worldPosition = instancePositions;

  // Multiply out radius and clamp to limits
  float outerRadiusPixels = clamp(
    project_size_to_pixel(progress * (40.0 + 40.0 * instanceHit)),
    radiusMinPixels, radiusMaxPixels
  );
  
  // Multiply out line width and clamp to limits
  float lineWidthPixels = clamp(
    project_size_to_pixel(lineWidthScale * instanceLineWidths),
    lineWidthMinPixels, lineWidthMaxPixels
  );

  // outer radius needs to offset by half stroke width
  outerRadiusPixels += stroked * lineWidthPixels / 2.0;

  // position on the containing square in [-1, 1] space
  unitPosition = positions.xy;
  geometry.uv = unitPosition;

  innerUnitRadius = 1.0 - stroked * lineWidthPixels / outerRadiusPixels;
  
  vec3 offset = positions * project_pixel_size(outerRadiusPixels);
  DECKGL_FILTER_SIZE(offset, geometry);
  gl_Position = project_position_to_clipspace(instancePositions, instancePositions64xyLow, offset, geometry.position);
  DECKGL_FILTER_GL_POSITION(gl_Position, geometry);

  // Apply opacity to instance color, or return instance picking color
  vFillColor = vec4(instanceFillColors.rgb, instanceFillColors.a * opacity);
  DECKGL_FILTER_COLOR(vFillColor, geometry);
  vLineColor = vec4(instanceLineColors.rgb, instanceLineColors.a * opacity);
  DECKGL_FILTER_COLOR(vLineColor, geometry);
  
  // Set color to be rendered to picking fbo (also used to check for selection highlight).
  picking_setPickingColor(instancePickingColors);
}
`;
