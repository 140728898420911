import React from 'react';
import './DetailInfo.css';

interface Props {
  showDetail: boolean;
  layout: any;
}

const DetailInfo = ({ showDetail, layout }: Props) => {
  const imgBasePath = `/img/detail-${layout}`;
  return (
    <div id="detail-info" className={`${layout} ${showDetail ? 'show' : 'hide'}`}>
      <img
        id="detail-img"
        className={layout}
        alt="detail"
        src={`${imgBasePath}.png`}
        srcSet={`${imgBasePath}@2x.png 2x`}
      />
    </div>
  );
};

export default DetailInfo;
