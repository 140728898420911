import { ScatterplotLayer } from '@deck.gl/layers';
import vs from './BumpsStrokeLayer-vertex.glsl';

// todo - try to solve hard edges (if still visible during animation), by edges softening or adding extra transparent edge/s - maybe it can be tweaked by some of the params
// hit points anim - https://github.com/uber/deck.gl/issues/2555#issuecomment-452391692

// TODO - plan - just render all points for now and add time value + update shader to animate opacity/scale
export default class BumpsStrokeLayer extends ScatterplotLayer {
  constructor(props: any) {
    super(props);
  }

  initializeState() {
    super.initializeState();

    this.state.attributeManager.addInstanced({
      instanceEntryTime: { size: 1, accessor: 'getEntryTime' }, // todo - consider adding transition
      instanceHit: { size: 1, accessor: 'getEntryHit' },
    });
  }

  draw({ uniforms }: any) {
    super.draw({
      uniforms: {
        ...uniforms,
        currentTime: this.props.currentTime,
        duration: this.props.duration,
      },
    });
  }

  getShaders() {
    return Object.assign({}, super.getShaders(), {
      vs,
    });
  }
}

BumpsStrokeLayer.defaultProps = {
  currentTime: 0,
  duration: 50,
  getEntryTime: { type: 'accessor', value: 1 },
  getEntryHit: { type: 'accessor', value: 1 },
};
