import React from 'react';
import './Logo.css';

interface Props {
  layout: any;
}

const Logo = ({ layout }: Props) => {
  const imgBasePath = `/img/logo-${layout}`;
  return <img id="logo" className={layout} alt="logo" src={`${imgBasePath}.png`} srcSet={`${imgBasePath}@2x.png 2x`} />;
};

export default Logo;
