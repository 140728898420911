// todo - temp raw implementation based on ScatterplotLayer

import { Layer, createIterable, fp64LowPart } from '@deck.gl/core';
import GL from '@luma.gl/constants';
import { Model, Geometry } from '@luma.gl/core';

import vs from './vertex.glsl';
import fs from './fragment.glsl';

const DEFAULT_COLOR = [0, 0, 0, 255];

const defaultProps = {
  radiusScale: { type: 'number', min: 0, value: 1 },
  radiusMinPixels: { type: 'number', min: 0, value: 0 }, //  min point radius in pixels
  radiusMaxPixels: { type: 'number', min: 0, value: Number.MAX_SAFE_INTEGER }, // max point radius in pixels

  lineWidthUnits: 'meters',
  lineWidthScale: { type: 'number', min: 0, value: 1 },
  lineWidthMinPixels: { type: 'number', min: 0, value: 0 },
  lineWidthMaxPixels: { type: 'number', min: 0, value: Number.MAX_SAFE_INTEGER },

  stroked: false,
  filled: true,

  getPosition: { type: 'accessor', value: (x: any) => x.position },
  getRadius: { type: 'accessor', value: 1 },
  getFillColor: { type: 'accessor', value: DEFAULT_COLOR },
  getLineColor: { type: 'accessor', value: DEFAULT_COLOR },
  getLineWidth: { type: 'accessor', value: 1 },
  getEntryPos: { type: 'accessor', value: 1 },
  getEntryTime: { type: 'accessor', value: 1 },

  // deprecated
  strokeWidth: { deprecatedFor: 'getLineWidth' },
  outline: { deprecatedFor: 'stroked' },
  getColor: { deprecatedFor: ['getFillColor', 'getLineColor'] },
};

export default class DotsLayer extends Layer {
  constructor(props: any) {
    super(props);
  }

  getShaders() {
    return super.getShaders({ vs, fs, modules: ['project32', 'picking'] });
  }

  initializeState() {
    this.getAttributeManager().addInstanced({
      instancePositions: {
        size: 3,
        type: this.use64bitPositions() ? GL.DOUBLE : GL.FLOAT,
        accessor: 'getPosition',
      },
      instancePos: { size: 1, accessor: 'getEntryPos' }, // todo - consider adding transition
      instanceTime: { size: 1, accessor: 'getEntryTime' },
      instancePositions64xyLow: {
        size: 2,
        accessor: 'getPosition',
        update: this.calculateInstancePositions64xyLow,
      },
      instanceRadius: {
        size: 1,
        transition: true,
        accessor: 'getRadius',
        defaultValue: 1,
      },
      instanceFillColors: {
        size: this.props.colorFormat.length,
        transition: true,
        normalized: true,
        type: GL.UNSIGNED_BYTE,
        accessor: 'getFillColor',
        defaultValue: [0, 0, 0, 255],
      },
      instanceLineColors: {
        size: this.props.colorFormat.length,
        transition: true,
        normalized: true,
        type: GL.UNSIGNED_BYTE,
        accessor: 'getLineColor',
        defaultValue: [0, 0, 0, 255],
      },
      instanceLineWidths: {
        size: 1,
        transition: true,
        accessor: 'getLineWidth',
        defaultValue: 1,
      },
    });
  }

  updateState({ props, oldProps, changeFlags }: any) {
    super.updateState({ props, oldProps, changeFlags });
    // todo - dirty as hell
    // if (changeFlags.extensionsChanged) {
    const { gl } = this.context;
    if (this.state.model) {
      this.state.model.delete();
    }
    this.setState({ model: this._getModel(gl) });
    this.getAttributeManager().invalidateAll();
    // }
  }

  draw({ uniforms }: any) {
    const { viewport } = this.context;
    const {
      radiusScale,
      radiusMinPixels,
      radiusMaxPixels,
      stroked,
      filled,
      lineWidthUnits,
      lineWidthScale,
      lineWidthMinPixels,
      lineWidthMaxPixels,
    } = this.props;

    const widthMultiplier = lineWidthUnits === 'pixels' ? viewport.distanceScales.metersPerPixel[2] : 1;

    this.state.model
      .setUniforms(
        Object.assign({}, uniforms, {
          stroked: stroked ? 1 : 0,
          filled,
          radiusScale,
          radiusMinPixels,
          radiusMaxPixels,
          lineWidthScale: lineWidthScale * widthMultiplier,
          lineWidthMinPixels,
          lineWidthMaxPixels,
        }),
      )
      .draw();
  }

  _getModel(gl: any) {
    // a square that minimally cover the unit circle
    const positions = [-1, -1, 0, -1, 1, 0, 1, 1, 0, 1, -1, 0];

    return new Model(
      gl,
      Object.assign(this.getShaders(), {
        id: this.props.id,
        geometry: new Geometry({
          drawMode: GL.TRIANGLE_FAN,
          vertexCount: 4,
          attributes: {
            positions: { size: 3, value: new Float32Array(positions) },
          },
        }),
        isInstanced: true,
        shaderCache: this.context.shaderCache,
      }),
    );
  }

  calculateInstancePositions64xyLow(attribute: any, { startRow, endRow }: any) {
    const isFP64 = this.use64bitPositions();
    attribute.constant = !isFP64;

    if (!isFP64) {
      attribute.value = new Float32Array(2);
      return;
    }

    const { data, getPosition } = this.props;
    const { value, size } = attribute;
    let i = startRow * size;
    const { iterable, objectInfo } = createIterable(data, startRow, endRow);
    for (const object of iterable) {
      objectInfo.index++;
      const position = getPosition(object, objectInfo);
      value[i++] = fp64LowPart(position[0]);
      value[i++] = fp64LowPart(position[1]);
    }
  }
}

DotsLayer.layerName = 'DotsLayer';
DotsLayer.defaultProps = defaultProps;
