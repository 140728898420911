import { ScatterplotLayer } from '@deck.gl/layers';
import vs from './BumpsFillLayer-vertex.glsl';

// todo - secondary layer - polish stroke first and apply knowledge to this one

export default class BumpsStrokeLayer extends ScatterplotLayer {
  constructor(props: any) {
    super(props);
  }

  initializeState() {
    super.initializeState();

    this.state.attributeManager.addInstanced({
      instanceEntryTime: { size: 1, accessor: 'getEntryTime' },
      instanceHit: { size: 1, accessor: 'getEntryHit' },
    });
  }

  draw({ uniforms }: any) {
    super.draw({
      uniforms: {
        ...uniforms,
        currentTime: this.props.currentTime,
        durationIn: this.props.durationIn,
        durationOut: this.props.durationOut,
      },
    });
  }

  getShaders() {
    return Object.assign({}, super.getShaders(), {
      vs,
    });
  }
}

BumpsStrokeLayer.defaultProps = {
  currentTime: 0,
  durationIn: 35,
  durationOut: 450,
  getEntryTime: { type: 'accessor', value: 1 },
  getEntryHit: { type: 'accessor', value: 1 },
};
